import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/about": [13],
		"/apps": [14,[2]],
		"/apps/(app)/Morse-Code-Translator": [15,[2,3]],
		"/apps/(app)/advanced-emoji-tools": [16,[2,3]],
		"/apps/(app)/budget-tracker": [17,[2,3]],
		"/apps/(app)/currency-converter": [18,[2,3]],
		"/apps/(app)/english-dictionary-app": [19,[2,3]],
		"/apps/(app)/github-contribution-tracker": [20,[2,3,4]],
		"/apps/(app)/github-contribution-tracker/[user]/[year]": [~21,[2,3,4]],
		"/apps/(app)/qr-code-generator": [22,[2,3]],
		"/apps/(app)/random-emoji-generator": [23,[2,3]],
		"/apps/(app)/random-password-generator": [24,[2,3]],
		"/(auth)/error": [6],
		"/err": [~25],
		"/(auth)/login": [~7],
		"/(auth)/logout": [~8],
		"/(auth)/profile": [~9],
		"/(auth)/register": [~10],
		"/(auth)/signin": [~11],
		"/(auth)/signout": [12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';